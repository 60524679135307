body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  color: #f5f5f7;
}

.container {
  padding: 20px;
  text-align: center;
}

.airpod {
  width: 100px;
}

.airpod + .airpod {
  margin-left: 10px;
}

.stereo-button {
  margin: 20px 0;
  padding: 10px;
  color: #f5f5f7;
  background: none;
  border: 0;
  font-size: 20px;
  font-weight: bold;
}

.green {
  color: #02cc01;
}

a, a:hover, a:visited {
  color: #2997ff;
  text-decoration: none;
  font-weight: bold;
}

.no-margin {
  margin: 0;
}

.shaking {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 2s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
